<template>
  <div>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-table
          :data="table.transportList"
          border
          max-height="700"
          :row-style="changeRowStyle"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#f1f1f1',color:'#000000',padding: '9px 0'}"
          style="width: 100%"
          row-key="id"
          ref="someTable">
        <el-table-column prop="createDate" label="申请时间" width="130" align="center"/>
<!--        <el-table-column prop="orderNumber" label="订单编号" align="center" :show-overflow-tooltip="true"/>-->
<!--        <el-table-column prop="brandName" label="婚纱名称" width="130" align="center" >-->
<!--          <template slot-scope="scope">-->
<!--            {{ `${scope.row.styleType}-${scope.row.styleName}-${scope.row.clothesSize}-${scope.row.clothesNo}` }}-->
<!--          </template>-->
<!--        </el-table-column>-->

<!--        <el-table-column prop="reasonId" label="申请理由" align="center" >-->
<!--          <template slot-scope="scope">-->
<!--            {{ scope.row.reasonId===1?'试纱':'出件'}}-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="usageDate" label="使用日期" align="center" >
          <template slot-scope="scope">
            {{ scope.row.usageDate+'个月'}}
          </template>
        </el-table-column>
        <el-table-column prop="tenantName" label="申请公司" align="center"/>
        <el-table-column prop="empName" label="申请人" align="center"/>
        <el-table-column prop="localShopName" label="原店铺" align="center"/>
<!--        <el-table-column prop="localPositionName" label="原位置" align="center"/>-->
        <el-table-column prop="shopName" label="目标店铺" align="center"/>
        <el-table-column prop="positionName" label="目标位置" align="center"/>
        <el-table-column label="申请状态" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <p :style="{color:returnState(scope.row)[1],fontWeight:'bold'}">
              {{returnState(scope.row)[0] }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center"/>
        <el-table-column width="250" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group >
              <el-button type="success" size="mini" v-if="returnButton(scope.row,'pass')" @click="requestAgree(scope.row)">通过</el-button>
              <el-button type="danger" size="mini" v-if="returnButton(scope.row,'refuse')" @click="requestRefuse(scope.row)">拒绝</el-button>
              <el-button type="warning" size="mini" v-if="returnButton(scope.row,'writeExpress')" @click="addExpressNumber(scope.row)">填写快递单</el-button>
              <el-button type="primary" size="mini" @click="openClothes(scope.row)">查看申请</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>

    <transportListClothes v-if="transportListClothesState" :orderNumber="orderNumber" :state="transportListClothesState" @close="transportListClothesState=false"/>
  </div>
</template>

<script>
import transportListClothes from "@/pages/share/share-clothes/transport/transport-list-clothes";

export default {
name: "transport-list-admin.vue",
  data() {
    return {
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        transportList: [],
        page: 1,
        limit: 100,
        total: 0,
      },

      transportListClothesState:false,
      orderNumber:"",
    }
  },
  components:{transportListClothes},
  created() {
    this.queryTransportList();

  },
  methods: {
    //查询客户列表
    queryTransportList: function () {
      this.$axios({
        method: "GET",
        url: "/libClothesTransport/transportList",
        params: {
          tenantCrop: this.tenantCrop,
          page: this.table.page,
          limit: this.table.limit,

        }
      }).then(response => {

        this.table.total = response.data.data.total;
        this.table.transportList = this.dataGroup(response.data.data.list);
      })
    },
    //申请通过 状态改为待发货
    requestAgree(item){
      this.$alert("是否同意此调货申请?",
          "调货审核",
          {
            dangerouslyUseHTMLString: true,
            type: "success",
            showCancelButton: true
          }).then(() => {
        this.$axios({
          method: "POST",
          url: "/libClothesTransport/updateTransportByOrderNum",
          params: {
            id: item.id,
            state: 3,
            refusalRemark: "",
            orderNumber: item.orderNumber,
          }
        }).then(response => {
          response.data.code===200?this.$message.success("审批成功!"):this.$message.error(response.data.msg);
          this.queryTransportList();
        })
      })
    },
    //申请拒绝
    requestRefuse(item){
      this.$prompt('请输入拒绝理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.$axios({
          method: "POST",
          url: "/libClothesTransport/updateTransportByOrderNum",
          params: {
            id: item.id,
            state: 2,
            refusalRemark: value,
            orderNumber: item.orderNumber,
          }
        }).then(response => {
          response.data.code===200?this.$message.success("已拒绝!"):this.$message.error(response.data.msg)
          this.queryTransportList();
        })
      })
    },
    //填写快递单号
    addExpressNumber(item){
      this.$prompt('请输入快递单号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.$axios({
          method: "POST",
          url: "/libClothesTransport/updateTransportByOrderNum",
          params: {
            id: item.id,
            state: 4,
            expressNumber: value,
            orderNumber: item.orderNumber,
          }
        }).then(response => {
          response.data.code===200?this.$message.success("成功!"):this.$message.error(response.data.msg)
          this.queryTransportList();
        })
      })
    },
    openClothes(item){
      this.orderNumber=item.orderNumber;
      this.transportListClothesState=true;
    },
    //返回状态信息
    returnState(item){
      switch (item.state){
        case 0: return ["申请中",'#E6A23C'];
        case 1: return ["通过",'green'];
        case 2: return [`拒绝 : ${item.refusalRemark}`,'red'];
        case 3: return [`待发货`,'blue'];
        case 4: return [`已发货 : ${item.expressNumber}`,'green'];
      }
    },
    //返回显示按钮
    returnButton(row,type){
      switch (type){
        case 'pass':
          return [0, 2].includes(row.state);
        case 'refuse':
          return [0,1,3].includes(row.state);
        case 'writeExpress':
          return [3].includes(row.state);
      }
      return false;
    },
    //返回分组后的list数据
    dataGroup(list){
      const m = new Map();
      //订单号为Key 把相同订单号的数据当做Value放在同一个Key中
      list.map(item=>{
        let arr=m.get(item.orderNumber);
        if(arr!==undefined&&arr.length>0){
          arr.push(item);
          m.set(item.orderNumber, arr)
        }else {
          m.set(item.orderNumber, [item])
        }
      })

      let dataList=[];
      m.forEach((v,k)=>{
        let oneDate=v[0];
        oneDate.children=[];
        //如果开启子表格 则取消下面注释
        // v.forEach((value,index)=>{
        //   if (index>0){
        //     oneDate.children.push(value)
        //   }
        // })
        dataList.push(oneDate);
      })
      return dataList;
    },
    //更改行样式
    changeRowStyle({row, rowIndex}){
      return row.children===undefined?{height: '50px',background: "#ffffff"}:{height: '50px',background: "#fafafc"};
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryTransportList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryTransportList()
    },
    close: function (state) {
      this.queryTransportList();
    },
  }
}
</script>

<style scoped>

</style>